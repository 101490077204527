<template>
  <b-card>
    <div class="mt-3 pull-left w-75">
      <div class="text-dark-blue text-center">
        <h1 class="f-w-600"> {{ title }} </h1>
        <h1 class="font-card-size-sub-title f-w-600"> {{ $t(subTitle) }} </h1>
      </div>
    </div>
    <div
        class="w-20 card-icon"
        :class="iconFont"
    >
      <span :class="iconClass"></span>
    </div>
  </b-card>
</template>
<script>
  export default {
      props: {
        title: {
          type: String,
          default: '',
        },
        subTitle: {
          type: String,
          default: '',
        },
        iconFont: {
          type: String,
          default: ''
        },
        iconClass: {
          type: String,
          default: ''
        }
      }
  }
</script>
<style scoped>
.card-body {
  padding-left: 15px;
  padding-right: 15px;
  background-color: transparent;
}
.card{
  max-width: 92%;
  border-radius: 10px;
  box-shadow: 0px 10px 16px #E2E1E6;
}

.card-icon{
  padding-left: 2.4vh;
  padding-right: 2.4vh;
  padding-top: 2vh;
  padding-bottom: 2vh;
  margin-top: 1vh;
  margin-right: -5vh;
  float: right;
  margin-BOTTOM: 1vh;
  border-radius: 1vh;
  box-shadow: 0 10px 16px #E2E1E6;
}

</style>